import { Box, Container, Flex, Text } from "@chakra-ui/react";
import {
  BsDiscord,
  BsFacebook,
  BsInstagram,
  BsTelegram,
  BsTwitter,
} from "react-icons/bs";

function Footer() {
  return (
    <Box
      py={"20px"}
      bg="linear-gradient(130deg, rgba(40,91,178,1) 0%, #0C1851 17%)"
    >
      <Container maxW="100vw" p="0px 22px">
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            fontSize={32}
            fontWeight={800}
            letterSpacing={1}
            color="brand.100"
            cursor="pointer"
          >
            Platfair
          </Text>
          <Flex color="brand.100" fontSize="22px">
            <Box cursor="pointer">
              <BsFacebook />
            </Box>
            <Box ms={2} cursor="pointer">
              <BsInstagram />
            </Box>
            <Box ms={2} cursor="pointer">
              <BsTwitter />
            </Box>
            <Box ms={2} cursor="pointer">
              <BsDiscord />
            </Box>
            <Box ms={2} cursor="pointer">
              <BsTelegram />
            </Box>
          </Flex>
        </Flex>
        <Text textAlign="center" color="brand.100" mt={2}>
          ©{new Date()?.getFullYear()}. All Rights Reserved
        </Text>
      </Container>
    </Box>
  );
}

export default Footer;
