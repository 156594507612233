import { Box, Button, Card, Heading } from "@chakra-ui/react";

const NftCard = (props: any) => {
  console.log("props", props);
  return (
    <Card
      minW={{ base: 200, sm: 300 }}
      width={{ base: 350, sm: 500 }}
      height="600px"
      cursor="pointer"
      role="group"
    >
      <Box
        height="100%"
        bgImage={
          "https://images.unsplash.com/photo-1645516484419-35a747c99474?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fG5mdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
        }
        bgPos="center"
      ></Box>
      <Box
        position={"absolute"}
        top="50%"
        left={["20%", "30%"]}
        transform="transalte(-40%, -50%)"
        textAlign="center"
      >
        <Heading bgGradient="linear(to-l, #EBEDF2, #1153C9)" bgClip="text">
          {props?.title}
        </Heading>
        <Button
          mt={3}
          bg="linear-gradient(152deg, rgba(17,29,81,1) 0%, rgba(58,111,220,1) 90%) !important"
          color="brand.100"
          w={200}
          opacity={0}
          _groupHover={{
            opacity: 1,
          }}
        >
          Mint
        </Button>
      </Box>
    </Card>
  );
};

export default NftCard;
