import { Box, Flex } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import Header from "../components/Header";
import Hero from "../components/Hero";
import NFTCarousel from "../components/NFTCarousel";
import NftCard from "../components/NftCard";

const Home = () => {
  return (
    <Box>
      <Box bg="linear-gradient(130deg, rgba(40,91,178,1) 0%, #0C1851 17%)">
        <Header />
        <Hero />
      </Box>
      <Flex justifyContent="center" overflow={"hidden"} my={5}>
        <NFTCarousel
          cards={cards}
          height="600px"
          width="90%"
          margin="0 auto"
          offset={2}
          showArrows={false}
        />
      </Flex>
    </Box>
  );
};

let cards = [
  {
    key: uuidv4(),
    content: <NftCard title="Premium" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Platinum" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Standard" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Gold" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Premium" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Platinum" />,
  },
  {
    key: uuidv4(),
    content: <NftCard title="Standard" />,
  },
];

export default Home;
