import { Button, Container, Flex, Heading, Text } from "@chakra-ui/react";

const Hero = () => {
  return (
    <Container
      maxW={{ base: "98vw", md: "73vw" }}
      minH={["55vh", "80vh"]}
      display="flex"
      flexDir="column"
      justifyContent="center"
      pb={10}
    >
      <Heading
        color="brand.100"
        fontWeight="bold"
        fontSize={{
          md: "62px",
          sm: "42px",
          base: "32px",
        }}
      >
        <Flex>
          Soluções
          <Text
            ms={2}
            bgGradient="linear(to-l, #EBEDF2, #1153C9)"
            bgClip="text"
          >
            360
          </Text>{" "}
        </Flex>{" "}
        <Flex>
          para projetos{" "}
          <Text
            ms={2}
            bgGradient="linear(to-l, #EBEDF2, #1153C9)"
            bgClip="text"
          >
            web3.
          </Text>
        </Flex>
      </Heading>
      <Text color="brand.100" opacity={0.7} mt={5}>
        Find the key to your growth.
      </Text>
      <Button
        mt={5}
        bg="linear-gradient(152deg, rgba(17,29,81,1) 0%, rgba(58,111,220,1) 90%) !important"
        color="brand.100"
        maxW={200}
      >
        Saiba mais
      </Button>
    </Container>
  );
};

export default Hero;
