import { extendTheme, theme as base } from "@chakra-ui/react";
const theme = extendTheme({
  colors: {
    brand: {
      50: "#0C1851",
      100: "#ebedf2",
      200: "#cdf781",
      300: "#0C1851",
      400: "#1153C9",
      500: "#1153C9",
    },
  },
  fonts: {
    heading: `Plus Jakarta Sans, ${base.fonts?.heading}`,
    body: `Plus Jakarta Sans, ${base.fonts?.body}`,
  },
});

export default theme;

// background: linear-gradient(90deg, rgba(235,237,242,1) 20%, rgba(17,83,201,1) 100%);
