import { Box } from "@chakra-ui/react";
import AppHeader from "../components/AppHeader";
import Gallery from "../components/Gallery";

function Dashboard() {
  return (
    <Box>
      <Box bg="linear-gradient(130deg, rgba(40,91,178,1) 0%, #0C1851 17%)">
        <AppHeader />
      </Box>
      <Gallery />
    </Box>
  );
}

export default Dashboard;
