import {
  Box,
  Button,
  Collapse,
  Container,
  Flex,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Container maxW="100vw" p="10px 22px" zIndex={1000}>
      <Stack direction="row" align="center">
        <Box fontSize={32} fontWeight={800} letterSpacing={1} color="brand.100">
          Platfair
        </Box>
        <Flex ms={20} color="brand.100" hideBelow="820px" fontSize={14}>
          <Text me={5} cursor="pointer">
            About
          </Text>
          <Text me={5} cursor="pointer">
            NFTs
          </Text>
          <Text me={5} cursor="pointer">
            Articles
          </Text>
          <Text me={5} cursor="pointer">
            Projects
          </Text>
          <Text me={5} cursor="pointer">
            Whitepaper
          </Text>
          <Text me={5} cursor="pointer">
            Contact
          </Text>
        </Flex>
        <Flex justify="end" width="100%">
          <Button
            p={4}
            fontSize={14}
            color="brand.50"
            onClick={() => navigate("/app")}
          >
            Connect
          </Button>
          <Box
            hideFrom="821px"
            ms={5}
            color="brand.100"
            cursor="pointer"
            onClick={onToggle}
          >
            <HiOutlineMenuAlt3 size="36px" />
          </Box>
        </Flex>
      </Stack>
      <Collapse in={isOpen}>
        <VStack color="brand.100" hideFrom="821px" py={3}>
          <Text me={5} cursor="pointer">
            About
          </Text>
          <Text me={5} cursor="pointer">
            NFTs
          </Text>
          <Text me={5} cursor="pointer">
            Articles
          </Text>
          <Text me={5} cursor="pointer">
            Projects
          </Text>
          <Text me={5} cursor="pointer">
            Whitepaper
          </Text>
          <Text me={5} cursor="pointer">
            Contact
          </Text>
        </VStack>
      </Collapse>
    </Container>
  );
};

export default Header;
