import { Box, Button, Card, Heading } from "@chakra-ui/react";
import { GoDownload } from "react-icons/go";

function GalleryCard(props: any) {
  return (
    <Card mt={5} width={320} cursor="pointer" role="group">
      <Box
        height="320px"
        bgImage={
          "https://images.unsplash.com/photo-1645516484419-35a747c99474?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fG5mdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
        }
        bgPos="center"
      ></Box>
      <Heading
        position="absolute"
        bottom="0"
        p={2}
        bgGradient="linear(to-l, #EBEDF2, #1153C9)"
        bgClip="text"
        fontSize="22px"
      >
        NFT #{props.number + 1}
      </Heading>

      <Button
        bg="linear-gradient(152deg, rgba(17,29,81,1) 0%, rgba(58,111,220,1) 90%) !important"
        color="brand.100"
        position="absolute"
        bottom="0"
        right="0"
        m={2}
        size="sm"
        fontSize="22px"
        opacity={0}
        _groupHover={{
          opacity: 1,
        }}
      >
        <GoDownload />
      </Button>
    </Card>
  );
}

export default GalleryCard;
