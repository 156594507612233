import { Box, Button, Container, Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
  const navigate = useNavigate();
  return (
    <Container maxW="100vw" p="10px 22px" zIndex={1000}>
      <Stack direction="row" align="center">
        <Box
          fontSize={32}
          fontWeight={800}
          letterSpacing={1}
          color="brand.100"
          cursor="pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          Platfair
        </Box>
        <Flex justify="end" width="100%">
          <Button p={4} fontSize={14} color="brand.50">
            Connect
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default AppHeader;
