import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import GalleryCard from "./GalleryCard";

function Gallery() {
  return (
    <Box py="20px">
      <Container maxW={["80vw", "95vw"]}>
        <Heading color="brand.50">Gallery</Heading>
        <Flex wrap="wrap" justifyContent="center" columnGap={4}>
          {new Array(20).fill(1)?.map((_, idx) => (
            <GalleryCard number={idx} />
          ))}
        </Flex>
      </Container>
    </Box>
  );
}

export default Gallery;
